<template>
  <v-container class="behavior_list_item_container pa-0 aut-list-items">
    <v-row dense>
      <v-col cols="12">
        <v-list nav dense class="transparent pa-0">
          <v-list-item-group>
            <ListItem
              v-for="(item, i) in paginatedResults"
              :key="i"
              :context="context"
              :attributes="attributes"
              :item="item"
              :fields="formFields"
              dense
            />
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col cols="12" v-if="loaderToggle" aut-paginator class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import paginationMixin from "@/components/pageContent/List/mixin.js";
import { componentDesignerMixin } from "@/components/mixin.js";
import ListItem from "./ListItem";

const debug = require("debug")("list_items"); // eslint-disable-line
export default {
  name: "ListItems",
  mixins: [componentDesignerMixin, paginationMixin],
  components: {
    ListItem,
  },
  props: {
    attributes: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.resetPaginatedResults();
  },
};
</script>
