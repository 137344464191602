<template>
  <div class="product_card">
    <slot></slot>
  </div>
</template>
<script>
const debug = require("debug")("atman.product_card_layout"); // eslint-disable-line
export default {
  name: "ProductCardLayout",
  props: {
    layout: {
      type: Object,
      default: () => {
        return {};
      },
    },
    context: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.product_card {
  height: 100%;
  .behaviour_form {
    height: 100%;
    padding-bottom: 3em; // ensures content dont overlap with absolutely postitioned element
    ::v-deep button.behaviour_bottom {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
