<template>
  <div aut-list-table>
    <BulkOperationsDialog
      :definition="definition"
      :list="selected"
      v-if="displayBulkDialog"
      :context="context"
      @close="displayBulkDialog = false"
    >
    </BulkOperationsDialog>
    <CloneFieldDialog
      v-if="fieldToClone"
      :field="fieldToClone"
      :type="typeToClone"
      :definition.sync="definition"
      @close="fieldToClone = null"
      @update:definition="$emit('refresh_content', $event)"
    />
    <EditColumnDialog
      v-if="displayEditDialog"
      :isList="hasBulkOperation"
      :definition="formFields[index]"
      :context="context"
      @update:definition="editField($event, index)"
      @close="closeDialog"
    />
    <PermissionEditor
      v-if="permissionDialogToggle"
      :context="context"
      mode="field"
      :definition="formFields[index]"
      @close="permissionDialogToggle = false"
      @update="editField($event, index)"
    />
    <div v-if="isDesignMode">
      <draggable
        v-if="actions.length > 0"
        handle=".dragger"
        v-model="actions"
        class="d-flex justify-end pa-5"
        @end="updateActionsOrder(actions)"
        tag="div"
      >
        <Field
          v-for="(action, i) in actions"
          class="mx-2"
          :key="i"
          :definition="action"
          :path="`${action.name}`"
          :context="context"
          @remove_field="removeAction(action, i)"
          @update_field="updateAction($event, i)"
          @copy_field="copyAction(action, i)"
        />
      </draggable>
      <v-simple-table fixed-header height="300px">
        <template v-slot:default>
          <draggable handle=".dragger" v-model="formFields" tag="thead">
            <th
              :aut-field="col.name"
              :aut-field-name="col.name"
              :class="`text-center pa-4 ${getBackgroundColor()}`"
              :style="getColumnStyles(col)"
              v-for="(col, index) in formFields"
              :key="`${col.label || col.name}-${index}`"
            >
              <EditorWrapper
                :is_design_mode="true"
                classes="mt-n5 mr-n5 pa-2"
                @update:definition="editField($event, index)"
                :definition="col"
                :context="context"
                :actions="designActions(col)"
                @edit_field="showColEditDialog(index)"
                @remove_field="removeField(index)"
                @copy_field="copyField(col)"
                @edit_permissions="showColPermissionsDialog(index)"
              >
                {{ col.label || "{" + col.name + "}" }}
              </EditorWrapper>
            </th>
          </draggable>
          <tbody v-if="results.length == 0">
            <tr>
              <td
                v-for="(col, index) in formFields"
                :key="`${col.label || col.name}-${index}`"
              >
                <v-row justify="center">
                  <v-skeleton-loader boilerplate type="table-cell" />
                </v-row>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="(item, index) in results" :key="index">
              <td
                v-for="col in formFields"
                :key="`${col.label || col.name}-${index}`"
                class="text-left"
              >
                <Field
                  :key="`[${index}].${col.name}`"
                  :definition="getFieldDefinition(col)"
                  :path="`[${effectiveIndex(item)}].${col.name}`"
                  :index="effectiveIndex(item)"
                  :context="context"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <AddFieldDialog
        @add="addField"
        :context="context"
        :definition="definition"
        :filter="FIELDS_FILTER_LIST"
        :presets="presets"
      />
    </div>
    <div v-else>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="results"
        :show-select="hasBulkOperation"
        item-key="id"
        :loading="loader"
        :hide-default-footer="isServerSidePagination"
        @click:row="handleRowSelection"
        v-bind="tableAttributes"
        v-bind:class="displayClasses"
        dense
        aut-list-data-table
        class="behaviour_list_table"
      >
        <template
          v-for="header in headers"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <div :key="getPath(item, header)">
            <Field
              aut-table-col
              :definition="getDefinition(header)"
              :path="getPath(item, header)"
              :index="getActualIndex(item)"
              :context="context"
            />
          </div>
        </template>
        <template slot="body.append" v-if="footers.length">
          <tr v-if="displayFooterAsColumns">
            <th
              v-for="(footer, i) in footers"
              :key="`footer-${footerRefresh}-${i}`"
            >
              <Field aut-table-footer :definition="footer" :context="context" />
            </th>
          </tr>
          <tr v-else>
            <td
              :colspan="headers.length"
              class="behavior-table-footer"
              :class="footerDisplayClasses"
            >
              <div
                v-for="(footer, i) in footers"
                :key="`footer-${footerRefresh}-${i}`"
              >
                <Field
                  aut-table-footer
                  :definition="footer"
                  :context="context"
                />
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <ListPaginator
        v-if="isServerSidePagination"
        v-model="page"
        :pagination_start="pagination_start"
        :stop_pagination="stop_pagination"
        :paginatorToggle="paginatorToggle"
        :pagination="pagination"
        :loaderToggle="false"
        :totalRecords="totalRecords"
        @next="loadNextPage"
        @previous="loadPrevPage"
      />
    </div>
  </div>
</template>
<script>
import { componentDesignerMixin } from "@/components/mixin.js";
import BulkOperationsDialog from "./BulkOperationsDialog";
import { omit, defaultsDeep } from "lodash";
import {
  registerToEvents,
  clone,
  getHeaderBackgroundColor,
  getBackgroundColor,
  uniqueID,
} from "@/util.js";
import { STORE_CONSTS } from "@/components/fields/store.js";
import { FIELDS_FILTER_LIST } from "@/components/fields/util.js";
import paginationMixin from "@/components/pageContent/List/mixin.js";
import EditorWrapper from "@/components/editor/EditorWrapper";
import draggable from "vuedraggable";
import { mapState } from "vuex";
import { PERFORM_BULK_OPERATIONS, getClickAction } from "../util.js";
import permissionsMixin from "@/components/permissionsMixin.js";

const debug = require("debug")("atman.components.list.list_table"); // eslint-disable-line

export default {
  name: "ListTable",
  mixins: [componentDesignerMixin, paginationMixin, permissionsMixin],
  components: {
    EditorWrapper,
    BulkOperationsDialog,
    draggable,
    EditColumnDialog: () =>
      import("@/components/pageContent/List/ListTable/EditColumnDialog"),
  },
  props: {
    loader: {},
    footerData: {},
    attributes: {},
    context: {
      type: String,
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          count: 10,
          mode: "client",
        };
      },
    },
    results: {
      type: Array,
      default: () => [],
    },
    originalResults: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState(["skin", "preferences"]),
    headerClasses() {
      return this.attributes?.table?.header?.classes || [];
    },
    totalRecords() {
      let result = this.paginatedResults.length;
      return result;
    },
    tableAttributes() {
      const component = this;
      let result = {};
      const paginationCount =
        component.paginationCount || component.pagination?.count;
      const mobileBreakPoint = this.$vuetify?.breakpoint?.mobileBreakpoint;
      if (mobileBreakPoint) {
        result = {
          "mobile-breakpoint": mobileBreakPoint,
        };
      }

      const hideTableFooter =
        component.attributes?.table?.["hide-default-footer"] === true;
      if (paginationCount && !hideTableFooter) {
        result["items-per-page"] = paginationCount;
      }
      result = defaultsDeep(result, component.attributes.table || {});
      if (hideTableFooter) {
        delete result["footer-props"];
      }
      // NOTE: the options `items-per-page-options` should also be overwritten
      return result;
    },
    actions: {
      get() {
        const component = this;
        const tableDefinition = component?.definition?.definition || {};
        const results = clone([...(tableDefinition?.actions || [])]);
        if (this.hasBulkOperation) {
          const action = {
            name: PERFORM_BULK_OPERATIONS,
            label: "Bulk Operations",
            _ignore_in_page_editor_: true,
            disabled: !(
              (component.selected?.length || 0) > 0 &&
              component.bulkUpdateFields.length > 0
            ),
            type: "action",
            value: {
              type: "event",
              name: PERFORM_BULK_OPERATIONS,
            },
          };
          results.push(action);
        }
        if (!this?.selected?.length) {
          results.forEach((action) => {
            if (action.bulk_operation) {
              action.disabled = true;
            }
          });
        }
        debug(`results`, results);
        return results.map((item) => {
          item.id = item.id || uniqueID();
          return item;
        });
      },
      set(value) {
        this.updateActionsOrder(value);
      },
    },
    noHeaderColor() {
      const component = this;
      const colorAttribute =
        component?.attributes?.table?.header?.behavior_color ||
        component?.attributes?.table?.header?.color;
      return colorAttribute == "none";
    },
    hasBulkOperation() {
      return !!this?.definition?.definition?.bulk_operation;
    },
    selected: {
      get() {
        const component = this;
        return component.$store.state[`${component.context}`].selected;
      },
      set(value) {
        const component = this;
        const mutation = `${component.context}/${STORE_CONSTS.SELECTED}`;
        component.$store.commit(mutation, value);
      },
    },
    isDesignMode() {
      return this.$store.state[this.context].design;
    },
    bulkUpdateFields() {
      return (this?.formFields || []).filter((field) => !!field.bulk_operation);
    },
    displayFooterAsColumns() {
      return (
        this.definition?.definition?.footers?.display?.attributes
          ?.display_as_columns ?? true
      );
    },
    footerDisplayClasses() {
      return (
        this.definition?.definition?.footers?.display?.classes || []
      ).join(" ");
    },
    footers() {
      const result = this.definition?.definition?.footers?.fields || [];
      const footerData = this.footerData;
      if (footerData && Object.keys(footerData)) {
        result.forEach((footer) => {
          footer.value = footerData[footer.name];
        });
      }
      debug(`footers`, JSON.stringify(result));
      return result;
    },
  },
  watch: {
    footers() {
      this.footerRefresh++;
    },
    results() {
      debug(`results updated`, this.results);
      this.updateSelected();
    },
    selected() {
      debug(`emitting selection_changed`);
      this.$emit("selection_changed", this.selected);
    },
  },
  data() {
    return {
      headers: [],
      displayEditDialog: false,
      index: null,
      displayBulkDialog: false,
      presets: {
        mode: "display",
      },
      footerRefresh: 1,
    };
  },
  created() {
    this.FIELDS_FILTER_LIST = FIELDS_FILTER_LIST;
    this.getBackgroundColor = getBackgroundColor;
  },
  mounted() {
    debug(
      `mounted of ListTable`,
      this.definition,
      this.results,
      this.attributes
    );
    this.registerEvents();
    this.prepareHeaders();
    this.$nextTick(() => {
      this.showResizableGrid();
    });
  },
  methods: {
    showColPermissionsDialog(index) {
      const component = this;
      component.index = index;
      component.permissionDialogToggle = true;
    },
    effectiveIndex(item) {
      return (this.originalResults || []).findIndex(({ id }) => id == item.id);
    },
    prepareHeaders() {
      let color = getHeaderBackgroundColor();
      let tableHeaderClasses = `behaviour_list_table-header ${
        this.noHeaderColor ? "" : color
      }`;
      tableHeaderClasses += this.headerClasses.join(" ");
      let result = [];
      result = result.concat(
        (this.formFields || []).map((column) => {
          const columnWidth = this.getColumnStyles(column).width;
          return {
            text: column.label,
            sortable: true,
            divider: false,
            align: "start",
            value: column.name,
            width: columnWidth,
            class: tableHeaderClasses,
          };
        })
      );
      debug(`in getter of headers`, result);

      this.headers = result;
    },
    async handleRowSelection(item) {
      const component = this;
      debug(`handleRowSelection`, item);
      const index = component.originalResults.findIndex(
        ({ id }) => id == item.id
      );
      if (index == -1) {
        console.error(`Unexpected error in handleRowSelection. item not found`);
        return;
      }
      let actionDefinition = getClickAction(
        component.definition?.definition?.fields
      );
      if (!actionDefinition) {
        return;
      }

      // TODO refactor duplicated code
      const replaceIndex = (url) => {
        if (!url) {
          return url;
        }
        let result = url;

        debug(`Replacing in ${url} with index`, index);
        result = url.replace("[i]", `[${index}]`);
        debug(`returning ${result}`);
        return `${result}`;
      };
      let payload = {
        actionDefinition,
        customFunctions: replaceIndex,
      };
      try {
        await component.$store.dispatch(
          `${component.context}/triggerAction`,
          payload
        );
      } finally {
        payload = null;
      }
    },
    getFieldDefinition(col) {
      const definition = omit(col, "label");
      return definition;
    },
    updateSelected() {
      if (!this.selected.length) {
        return;
      }
      this.selected = this.selected.filter(({ id }) => {
        return this.results.find((result) => {
          return result.id == id;
        });
      });
    },

    triggerBulkOperations() {
      this.displayBulkDialog = true;
    },

    registerEvents() {
      const component = this;
      const unsubscribe = registerToEvents({
        id: component.context,
        $store: component.$store,
        events: [
          {
            name: PERFORM_BULK_OPERATIONS,
          },
        ],
        callBackFn: () => {
          component.triggerBulkOperations();
        },
      });
      component._subscribe(unsubscribe);
    },
    getPath(item, header) {
      const component = this;
      const index = component.getActualIndex(item);
      const col = component.formFields.find(
        (field) => field.name == header.value
      );
      return col.is_container ? `[${index}]` : `[${index}].${col.name}`;
    },
    getDefinition(header) {
      debug(header);
      const column = this.formFields.find(
        (field) => field.name == header.value
      );
      return omit(column, "label");
    },
    designActions() {
      return [
        {
          id: `edit-field`,
          hint: "Edit",
          label: "Edit",
          icon: "mdi-pencil",
          event: "edit_field",
          param: "",
        },
        {
          id: "edit_permissions",
          label: "Change Permissions",
          icon: "mdi-key",
          event: "edit_permissions",
          param: "",
        },
        {
          id: `remove-field`,
          label: "Remove",
          confirmation: "Are you sure you want to continue?",
          icon: "mdi-delete",
          event: "remove_field",
          param: "",
        },
        {
          id: "clone-field",
          label: "Clone",
          icon: "mdi-content-copy",
          event: "copy_field",
        },
        {
          id: "move-field",
          label: "Move",
          icon: "mdi-drag",
          classes: "dragger",
        },
      ];
    },

    getTableRowKey(item) {
      const index = this.getActualIndex(item);
      return `${index}-${JSON.stringify(item)}`;
    },
    getActualIndex(item) {
      return this.originalResults.findIndex(({ id }) => item.id == id);
    },
    updateColumn() {
      const component = this;
      this.$emit(`update:definition`, this.tableDefinition);
      component.closeDialog();
    },
    showColEditDialog(index) {
      const component = this;
      component.index = index;
      component.displayEditDialog = true;
    },
    closeDialog() {
      this.displayEditDialog = false;
    },
    getColumnStyles(field) {
      let width = field?.display?.width;
      width = width == "auto" ? "170px" : width;
      return {
        width: `${width}`,
      };
    },
    adjustHeader(header) {
      if (!header) {
        return;
      }
      debug(`adjusting header`);
      const colWidth = header.style.width;
      const fields = clone(this.formFields);
      // debug(`header`, header, header.innerText, colWidth);
      const formField = fields.find((field) => field.label == header.innerText);
      if (!formField) {
        return;
      }
      // debug(`formField`, formField);
      const widthOfTable = this.$el.clientWidth;
      let widthOfField =
        (colWidth.substring(0, colWidth.indexOf("px")) / widthOfTable) * 100;
      widthOfField = Number.parseFloat(widthOfField).toFixed(2);
      // debug(`widthOfTable`, );
      formField.display = formField.display || {};
      formField.display.width = `${widthOfField}`;
      this.formFields = fields;
    },
    showResizableGrid() {
      /* Removing the implementation since it is causing performance issues. 
      This code registers several event handlers which it was not removing afterwards */
      return;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  tr {
    cursor: pointer;
  }
  .behavior_dropdown_container {
    margin-top: 16px;
    font-size: small;
  }
  .behavior-no-borders.v-data-table td {
    border: 0px !important;
  }

  .theme--light {
    tbody tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
  .theme--dark {
    tbody tr:nth-of-type(odd) {
      background-color: rgb(255 255 255 / 12%);
    }
  }
}
.theme--light {
  .behaviour_table_variation ::v-deep {
    tbody tr:nth-of-type(n) {
      background-color: #fff;
    }
    .v-data-footer {
      background-color: #fff;
      margin-right: 0px !important;
    }
  }
}
.theme--dark {
  .behaviour_table_variation ::v-deep {
    tbody tr:nth-of-type(n) {
      background-color: #1e1e1e;
    }
    .v-data-footer {
      background-color: #1e1e1e;
      margin-right: 0px !important;
    }
  }
}
</style>
