<template>
  <div v-if="isBlockMode(field)">
    <v-row class="ma-0 pa-0" no-gutters>
      <v-col :cols="getWidth(field)" class="ma-0 pa-0">
        <slot></slot>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>
<script>
import { getWidth } from "@/components/fields/util.js";
const debug = require("debug")("atman.components.form_field");
debug(`form_field`);
export default {
  name: "FormField",
  props: {
    field: {
      type: Object,
    },
  },
  created() {
    this.getWidth = getWidth;
  },
  methods: {
    isBlockMode(field) {
      return field?.display?.block;
    },
  },
};
</script>
