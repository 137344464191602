<template>
  <div>
    <template>
      <div class="banner" aut-profile-banner>
        <v-btn
          v-if="isInputMode"
          @click="selectFile('banner')"
          icon
          color="primary"
          small
          aut-edit-banner
          class="edit_banner"
        >
          <v-icon dense>mdi-pencil</v-icon>
        </v-btn>
        <v-img
          position="top"
          v-if="bannerSource"
          aut-banner-image
          height="100"
          :src="bannerSource"
          :lazy-src="no_image"
        ></v-img>
        <div
          v-else
          aut-banner-background
          class="colored_background"
          :class="backgroundClasses"
        ></div>
      </div>
      <v-avatar
        size="70"
        class="mt-n8 behaviour_left behavior_profile_pic d-flex justify-center"
        aut-profile-avatar
      >
        <v-img
          v-if="avatarSource"
          aut-avatar-image
          :src="avatarSource"
          :lazy-src="no_image"
        >
        </v-img>
        <v-icon x-large aut-avatar-icon v-else>mdi-account</v-icon>
        <v-overlay
          v-if="isInputMode"
          aut-edit-avatar
          :value="true"
          @click="selectFile('avatar')"
          absolute
          class="behavior_clickable"
          color="grey lighten-4"
        >
          <v-icon color="primary" class="ml-7 mt-7">mdi-pencil</v-icon>
        </v-overlay>
      </v-avatar>
    </template>
    <div class="px-2">
      <slot></slot>
    </div>
    <input
      type="file"
      ref="imageinput"
      aut-image-input
      accept="image/*"
      @click.stop
      style="display: none"
      @change="uploadImage"
    />
  </div>
</template>
<script>
import {
  getDynamicValue,
  hasAVariable,
  setFieldValue,
  getFileURL,
  fetchRandomValue,
  getFileSrc,
} from "@/util.js";
import { isPlainObject } from "lodash";
import { mapState } from "vuex";
const debug = require("debug")("atman.profile_layout"); // eslint-disable-line

export default {
  name: "ProfileLayout",
  data() {
    return {
      avatarSource: "",
      bannerSource: "",
      uploadKey: "",
    };
  },
  props: {
    render_key: {},
    layout: {
      type: Object,
      default: () => {
        return {};
      },
    },
    context: {
      type: String,
    },
  },
  watch: {
    layout() {
      this.determineEffectiveLayout();
    },
    render_key() {
      this.determineEffectiveLayout();
    },
  },
  computed: {
    ...mapState(["no_image"]),
    backgroundClasses() {
      return this.layout?.background?.classes || [];
    },
    isInputMode() {
      return this.layout?.mode == "input";
    },
  },
  mounted() {
    this.determineEffectiveLayout();
  },
  methods: {
    async getDynamicImageSrc(key) {
      const hasSourceKey = this.layout?.[key]?.source;
      let result = await this.getSourceValue({
        field: hasSourceKey ? this.layout[key] : this.layout,
        key: hasSourceKey ? "source" : key,
      });

      result = hasAVariable(result) ? "" : result;

      if (!result) {
        const placeholder = this.layout[key]?.placeholder || "";

        if (typeof placeholder == "string") {
          result = await this.getSourceValue({
            field: this.layout[key],
            key: "placeholder",
          });
        } else if (isPlainObject(placeholder)) {
          result = await this.getPlaceholderValue(placeholder);
        } else {
          result = "";
        }
      }

      return hasAVariable(result) ? "" : result;
    },
    async getSourceValue({ field, key }) {
      let methodDebug = debug.extend(`getSourceValue${key ? "_" + key : ""}`); // eslint-disable-line
      try {
        let value = await getDynamicValue({
          field,
          key,
          context: this.context,
          store: this.$store,
        });
        methodDebug(`got value`, value);
        return getFileSrc(value);
      } catch (e) {
        methodDebug(`Unable to resolve value`, e);
      }

      return "";
    },
    determineEffectiveLayout() {
      this.setBackground();
      this.getDynamicImageSrc("avatar").then((avatar) => {
        debug(`setting avatarSource to `, avatar);
        this.avatarSource = avatar;
      });
      this.getDynamicImageSrc("banner").then((banner) => {
        debug(`setting banner to `, banner);
        this.bannerSource = banner;
      });
    },
    setBackground() {
      const background = this.layout?.background || "";
      if (!background) {
        return;
      }
      if (background?.color) {
        this.$el.style.setProperty("--background-color", background.color);
      }
      if (background?.height) {
        this.$el.style.setProperty(
          "--background-height",
          `${background.height}em`
        );
      }
    },
    uploadImage(e) {
      const files = e?.target?.files || [];

      const field = this.layout?.[this.uploadKey]?.field || "";
      if (!files.length || !this.uploadKey || !field) {
        return;
      }

      setFieldValue({
        store: this.$store,
        context: this.context,
        dataPath: field,
        newValue: files[0],
      });

      const fileURL = getFileURL(files[0]);
      if (this.uploadKey == "banner") {
        this.bannerSource = fileURL;
      } else {
        this.avatarSource = fileURL;
      }
    },
    selectFile(key) {
      this.uploadKey = key;
      this.$refs.imageinput.click();
    },
    async getPlaceholderValue(placeholder) {
      const result = await fetchRandomValue({
        store: this.$store,
        context: this.context,
        url: placeholder?.url,
        field: placeholder?.field,
        customFunction: getFileSrc,
      });
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_layout_container {
  overflow: hidden;
}
.colored_background {
  width: auto;
  height: var(--background-height, 5em);
  background-color: var(--background-color, var(--v-primary-darken1));
}
.behavior_profile_pic {
  background-color: white;
  border-radius: 50%;
}
.behaviour_left {
  display: block !important;
  margin-right: auto !important;
  margin-left: 16px !important;
}
.banner {
  position: relative;
  .edit_banner {
    position: absolute;
    bottom: 0.5em;
    right: 1em;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.205);
  }
}
</style>
