<template>
  <v-card
    flat
    class="pa-0 ma-0 contact_card d-flex flex-column"
    min-height="200px"
  >
    <div class="left_panel">
      <p
        class="px-4 pt-2 ma-0 text-left font-weight-bold firmName"
        aut-firm-name
      >
        {{ firmName }}
      </p>
      <p class="px-4 pt-2 ma-0 text-left font-weight-bold" aut-address-value>
        <span aut-address-city>{{ city }} </span
        ><span aut-address-state> {{ state }}</span>
      </p>
      <v-card-text class="py-0 px-3 font-weight-bold">
        <slot></slot>
      </v-card-text>
    </div>
    <div class="right_panel pa-0 d-flex flex-row">
      <div class="py-2" aut-firm-type>
        <v-icon class="icon" medium> {{ firmIcon }} </v-icon>
        {{ firmType }}
      </div>
      <div class="py-2" aut-list_owner_type>
        <v-icon class="icon" medium> mdi-account-circle-outline </v-icon>
        {{ memberStatus }}
      </div>
    </div>
  </v-card>
</template>
<script>
const debug = require("debug")("atman.layouts.contact_cards_layout"); // eslint-disable-line
export default {
  name: "ContactCardLayout",
  data() {
    return {
      firmName: "",
      firmType: null,
      memberStatus: null,
      city: "",
      state: "",
    };
  },
  props: {
    layout: {
      type: Object,
      default: () => {
        return {};
      },
    },
    context: {
      type: String,
    },
  },
  watch: {
    layout() {
      this.determineEffectiveLayout();
    },
  },
  computed: {
    firmIcon() {
      const firmType = this.firmType;
      switch (firmType) {
        case "Consultant": {
          return "mdi-eye";
        }
        case "Distributor": {
          return "mdi-distribute-horizontal-center";
        }
        case "Manufacturer": {
          return "mdi-office-building";
        }
        case "Retailer": {
          return "mdi-form-select";
        }
        case "Trader": {
          return "mdi-shopping";
        }
        case "Machinery": {
          return "mdi-tools";
        }
        case "Association/Trade Body": {
          return "mdi-account-group";
        }
      }
      return "mdi-circle-off-outline";
    },
  },
  mounted() {
    this.determineEffectiveLayout();
  },
  methods: {
    async getValue(field) {
      const component = this;
      const value = await component.$store.dispatch(
        `${component.context}/getFieldValue`,
        { path: field }
      );
      return value;
    },

    async determineEffectiveLayout() {
      this.firmName = await this.getValue("firm_name");
      const firmTypeValue = await this.getValue("firm_type");
      const status = await this.getValue("list_owner_type");
      this.city = await this.getValue("city");
      this.state = await this.getValue("state");
      this.memberStatus =
        status == "member" ? "Plexconcil Member" : "Plexconcil Non-Member";
      this.firmType =
        firmTypeValue != null ? firmTypeValue : "Firm Type Not Available";
      debug({
        name: this.firmName,
        type: this.firmType,
        status: this.memberStatus,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.behavior_list_card_container {
  .contact_card {
    height: 100%;
    color: #04471c;
    background-color: #ffffff;
    position: relative;
    .card_highlight {
      background-color: var(--v-secondary-base);
    }
    .firmName {
      font-size: 1.5em;
      p {
        font-size: 85%;
      }
    }
    .icon {
      //color: #7a281d;
      color: white;
    }
    .left_panel {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: center;
      position: relative;
      top: 20%;
      p::first-letter {
        text-transform: capitalize;
      }
    }
    .right_panel {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      flex-direction: coloumn;

      div {
        width: 50%;
        color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        background-color: #04471c;
      }
    }
  }
  &:nth-child(2n) {
    .contact_card {
      color: #6d466b;
      .right_panel div {
        background-color: #6d466b;
      }
    }
  }
  &:nth-child(3n) {
    .contact_card {
      color: #094074;
      .right_panel div {
        background-color: #094074;
      }
    }
  }
  &:nth-child(4n) {
    .contact_card {
      color: #432818;
      .right_panel div {
        background-color: #432818;
      }
    }
  }
  &:nth-child(5n) {
    .contact_card {
      color: #ec7d10;
      .right_panel div {
        background-color: #ec7d10;
      }
    }
  }
  &:nth-child(6n) {
    .contact_card {
      color: #71816d;
      .right_panel div {
        background-color: #71816d;
      }
    }
  }
}
</style>
