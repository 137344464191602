<template>
  <div v-if="result.length" aut-col-container>
    <draggable
      v-if="draggable"
      dense
      v-model="result"
      class="row row--dense"
      @end="triggerUpdate"
    >
      <slot></slot>
    </draggable>
    <v-row v-else dense>
      <slot></slot>
    </v-row>
  </div>
</template>
<script>
import { clone } from "@/util";
import draggable from "vuedraggable";

export default {
  name: "ListCardContainer",
  components: {
    draggable,
  },
  computed: {
    result: {
      get() {
        return clone(this.list);
      },
      set(val) {
        this.$emit("update", val);
      },
    },
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    draggable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    triggerUpdate($event) {
      this.$emit("update_list", $event, clone(this.result));
    },
  },
};
</script>
