import { getClickAction } from "./util";
import { isChildOf, removeArrayPlaceholder } from "@/util";
import { intersection } from "lodash";

const debug = require("debug")("atman.components.list.listItemMixin"); // eslint-disable-line

const inputElements = ["INPUT", "TEXTAREA", "A"];
const inputElementClasses = [
  "v-select__selections", //handles v-select options
  "v-input--selection-controls__ripple",
];

export default {
  data() {
    return {
      navigationAction: null,
    };
  },
  mounted() {
    this.deriveNavigationAction();
  },
  methods: {
    deriveNavigationAction() {
      let action = getClickAction(this.formFields);
      if (!action) {
        return;
      }
      action = JSON.stringify(action);
      action = JSON.parse(removeArrayPlaceholder(action));
      this.navigationAction = action;
    },
    navigateToAction(event) {
      const eventTarget = event.target.nodeName;
      const methodDebug = debug.extend("navigateToAction"); // eslint-disable-line
      if (inputElements.includes(eventTarget)) {
        methodDebug(
          `User pressed enter or clicked on an input. Ignoring navigation`
        );
        return;
      }
      if (
        intersection(Array.from(event?.target?.classList), inputElementClasses)
          .length
      ) {
        methodDebug(
          `User pressed enter or clicked on an input (class). Ignoring navigation`
        );
        return;
      }
      // Adding for options (v-select, v-autocomplete)
      if (isChildOf(event?.target, '[role="option"]')) {
        methodDebug(
          `User pressed enter or clicked on an option. Ignoring navigation`
        );
        return;
      }

      if (!this.navigationAction?.name) {
        return;
      }

      const button = event.target
        .closest(".behaviour_list_item")
        .querySelector(
          `[data-behavior-action="${this.navigationAction?.name}"]`
        );
      if (button) {
        button.click();
      }
    },
  },
};
