import { defaultsDeep, forOwn, isPlainObject, mapValues, remove } from "lodash";
import { getFilterString } from "@/components/pageContent/List/util";
import { isFeatureEnabled } from "@/feature_flags";
import { clone } from "@/util";
const debug = require("debug")("atman.components.list.mixin"); // eslint-disable-line

const hasWildCard = (value) => /^\*[a-zA-Z0-9]+\*$/.test(value);

const addWildCardToValue = (value) => {
  let output;
  if (typeof value == "string") {
    output = hasWildCard(value) ? value : `*${value}*`;
  } else if (isPlainObject(value)) {
    output = addWildCards(value);
  } else if (Array.isArray(value)) {
    output = value.map((item) => {
      return addWildCardToValue(item);
    });
  } else {
    debug(`unhandled value`, value, typeof value);
  }
  return output;
};
const addWildCards = (input = {}) => {
  let result = clone(input);

  forOwn(result, (value, key) => {
    result[key] = addWildCardToValue(value);
  });
  return result;
};

const updateAlpabeticSearch = (data) => {
  const result = mapValues(data, (value) => {
    return value.map((valueString) => `${valueString}*`);
  });

  return result;
};

const getPaginatedResults = (component) => {
  let resultsToRender = [];
  const results = component.results;
  if (component.pagination.mode == "server") {
    // If no results are being displayed, just add all
    component.paginatedResults = component.paginatedResults || [];
    if (component.paginatedResults.length == 0) {
      resultsToRender = [...results];
    } else {
      // If not, add only the ones that are not present
      const allIDs = results.map(({ id }) => id);
      const allExistingIDs = component.paginatedResults.map(({ id }) => id);
      const newIDs = allIDs.filter((id) => !allExistingIDs.includes(id));
      resultsToRender = results.filter((result) => newIDs.includes(result.id));
    }
  }
  debug(`resultsToRender`, resultsToRender.length, resultsToRender);
  component.displayResults(resultsToRender);
};

const resetPaginatedResults = (component) => {
  let paginatedResults = [...(component.paginatedResults || [])];
  // Results will now contain a super-set of all possible values.
  // first remove any items which are no longer valid for any reason
  remove(paginatedResults, (item) => {
    return !component.results.map(({ id }) => id).includes(item.id);
  });
  debug(`Paginated results before getPaginatedResults`, paginatedResults);
  component.$set(component, "paginatedResults", paginatedResults);
  component.$nextTick(() => {
    component.index = component.paginationCount || component.pagination.count;
    getPaginatedResults(component);
  });
};

const moreAvailable = (component) => {
  return !component.stop_pagination;
};

const loadNextPage = (component) => {
  if (!moreAvailable(component)) {
    debug(`Ignoring pagination trigger`);
    return;
  }
  component.loaderToggle = true;
  if (!component.isInfiniteScroll) {
    component.$set(component, "paginatedResults", []);
  }

  debug(
    `loadNextPage triggered. Will get next ${
      component.paginationCount || component.pagination.count
    } results. Total: ${component.paginatedResults.length}`
  );

  debug(`Emitting event: next_page`);
  component.$emit("next_page");
};

const loadPrevPage = (component) => {
  component.loaderToggle = true;
  if (!component.isInfiniteScroll) {
    component.$set(component, "paginatedResults", []);
  }
  debug(`Emitting event: prev_page`);
  component.$emit("prev_page");
};

const handleSearch = async (component) => {
  component.$set(component, "results", []);
  return await component.fetchData();
};

const getRuntimeQueryParams = (component) => {
  const params = {
    runtimeParams: {
      count: component.paginationCount,
      start: component.pagination_start,
    },
  };
  let searchData = component.searchData;

  if (typeof searchData == "string") {
    params.runtimeParams.search = `"${searchData}"`;
  }

  searchData = isPlainObject(searchData) ? searchData : {};
  debug(`searchData`, searchData);
  if (isPlainObject(searchData)) {
    forOwn(searchData, (value, key) => {
      const field = (component.definition.definition.fields || []).find(
        ({ name }) => name == key
      );
      if (!field) {
        return;
      }
      const fieldSearchParams = field?.search?.params;
      if (fieldSearchParams) {
        Object.assign(params.runtimeParams, fieldSearchParams);
      }
    });
  }
  if (isFeatureEnabled("search.advanced_search.add_wild_cards")) {
    searchData = addWildCards(searchData);
  }

  const alphabeticSearchData = updateAlpabeticSearch(
    component.alphabeticSearchData
  );

  let data = defaultsDeep(
    {},
    alphabeticSearchData,
    component.filterData,
    searchData
  );

  const fields = component?.definition?.definition?.fields || [];
  data = mapValues(data, (value, key) => {
    const field = fields.find(({ name }) => name == key);
    if (field?.multiple === true) {
      return addWildCardToValue(value);
    }
    return value;
  });

  let filters = getFilterString(data, component.definition);
  debug(`getRuntimeQueryParams: filters`, filters);
  if (filters) {
    params.runtimeParams.filter = filters;
  }
  return params;
};

const getEffectiveResults = (component, fetchedData = []) => {
  let input = fetchedData;
  if (!Array.isArray(input)) {
    input = [];
  }
  let effectiveResults = [...input];
  // If this is an infinite scroll, prepend the original list
  if (component.pagination?.type == "infinite_scroll") {
    effectiveResults = [...component.results, ...effectiveResults];
  }
  return effectiveResults;
};

const setPaginationFlags = (component, fetchedData = []) => {
  component.stop_pagination = false;
  debug(
    "setPaginationFlags",
    fetchedData?.length,
    component.paginationCount || component.pagination?.count
  );
  if (
    !fetchedData ||
    fetchedData.length == 0 ||
    fetchedData.length <
      (component.paginationCount || component.pagination?.count)
  ) {
    component.stop_pagination = true;
  }
};

export default {
  resetPaginatedResults,
  loadNextPage,
  loadPrevPage,
  handleSearch,
  getRuntimeQueryParams,
  getEffectiveResults,
  setPaginationFlags,
  addWildCards,
};
