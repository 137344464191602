import { isEqual } from "lodash";
import ListPaginator from "./ListPaginator";
import { getFeatureValue } from "@/feature_flags.js";
import { safeClone } from "@/util";
import { PAGINATION_START } from "@/constants";

const debug = require("debug")("atman.components.list.mixin"); // eslint-disable-line

import factory from "./pagination/factory";

const pushToArray = (target, items, iteration) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      items.forEach((item) => {
        target.push(item);
      });
      resolve();
    }, iteration * 10);
  });
};

export default {
  components: {
    ListPaginator,
  },
  props: {
    pagination: {
      type: Object,
      default: () => {
        return {
          count: 10,
          mode: "client",
        };
      },
    },
    context: {
      type: String,
    },
    results: {
      type: Array,
      default: () => [],
    },
    stop_pagination: {
      type: Boolean,
    },
    pagination_start: {
      type: Number,
    },
    loaderToggle: {
      type: Boolean,
    },
  },
  data() {
    const index = this.paginationCount || this.pagination.count;
    return {
      page: 1,
      index,
      paginatedResults: [],
    };
  },
  computed: {
    paginatorToggle() {
      if (this.paginationType != "paginated") {
        return false;
      }
      if (this.page > 1) {
        return true;
      }
      debug(
        "paginator Toggle",
        this.paginatedResults?.length,
        this.paginationCount
      );
      return this.paginatedResults?.length == this.paginationCount;
    },
    isInfiniteScroll() {
      const mode = this.pagination?.type || "infinite_scroll";
      return mode == "infinite_scroll";
    },
    paginationMode() {
      const mode = this.pagination?.mode;
      return mode ? mode : "client";
    },
    paginationType() {
      const mode = this.pagination?.type;
      return mode ? mode : "infinite_scroll";
    },
    paginationCount() {
      const result =
        this.attributes?.table?.["items-per-page"] || this.pagination.count;
      return result;
    },
    isServerSidePagination() {
      return this.pagination?.mode == "server";
    },
  },
  watch: {
    results(newResults, oldResults) {
      debug(`results watch triggered in pagination mixin`);
      if (isEqual(newResults, oldResults)) {
        debug(`new and old are same. ignoring`);
        return;
      }
      debug(
        `filtered results changed. triggering reset of paginated results`,
        newResults
      );
      factory.resetPaginatedResults(this);
    },
    pagination_start() {
      debug(`pagination_start changed to`, this.pagination_start);
      if (this.pagination_start == PAGINATION_START) {
        debug(`Resetting page to 1`);
        this.page = 1;
      }
    },
  },
  methods: {
    resetPaginatedResults() {
      factory.resetPaginatedResults(this);
    },
    displayResults(resultsToRender) {
      debug(
        `display paginated results invoked with`,
        safeClone(resultsToRender)
      );
      const component = this;
      // component.paginatedResults = [...component.paginatedResults, ...resultsToRender];
      const count =
        getFeatureValue("cards.number_to_render", this.paginationCount) * 1;
      debug(`Will render [${count}]  results at one go`);
      const rendered = [];
      let i = 1;
      debug(`before rendering`, safeClone(component.paginatedResults));
      while (resultsToRender.length) {
        const slicedResults = resultsToRender.splice(0, count);
        rendered.push(
          pushToArray(component.paginatedResults, slicedResults, i++)
        );
      }
      Promise.all(rendered).then(() => {
        debug(`rendered invoked with`, safeClone(component.paginatedResults));
      });
    },
    loadNextPage(index) {
      debug(`loadNextPage invoked for index: [${index}]`);
      factory.loadNextPage(this);
    },
    loadPrevPage(index) {
      debug(`loadPrevPage invoked for index: [${index}]`);
      factory.loadPrevPage(this);
    },
  },
};
