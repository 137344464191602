<template>
  <v-slide-group v-bind="sliderAttributes" :value="activeSliderItem">
    <v-slide-item
      v-for="(item, index) in results"
      :key="`${item.id}-${index}`"
      class="py-4"
      :value="item._url"
    >
      <v-sheet
        class="transparent pa-1 behaviour_list_item behavior_clickable"
        :width="sliderAttributes.width"
        @click.stop="navigateToAction($event)"
        v-ripple="!!navigationAction"
      >
        <AtmanComponent
          class="behaviour_slider_item"
          :context="contextObject"
          :definition="formDefinition"
          :data="item"
        />
      </v-sheet>
    </v-slide-item>
  </v-slide-group>
</template>
<script>
import { componentDesignerMixin } from "@/components/mixin.js";
import paginationMixin from "@/components/pageContent/List/mixin.js";
import listItemMixin from "../listItemMixin";
import { clone, removeArrayPlaceholder } from "@/util";

export default {
  name: "ListSlider",
  props: {
    attributes: {},
  },
  mixins: [componentDesignerMixin, paginationMixin, listItemMixin],
  components: {
    AtmanComponent: () => import("@/components/pageContent/AtmanComponent"),
  },
  data() {
    return {
      formDefinition: {},
    };
  },
  computed: {
    contextObject() {
      return this?.$store?.state[this?.context]?.context || {};
    },
    sliderAttributes() {
      return this.attributes?.slider || {};
    },
    activeSliderItem() {
      const activeUrl = this.$route?.path || "";
      return activeUrl;
    },
  },
  mounted() {
    this.deriveFormDefinition();
  },
  methods: {
    deriveFormDefinition() {
      const fields = clone(this.formFields || []).map((field) => {
        field.display = field.display || {};
        field.display.width = field.display.width || "12";
        return field;
      });
      let result = {
        type: "form",
        definition: {
          fields,
        },
        display: {
          attributes: this.sliderAttributes?.cards || {},
          classes: this.sliderAttributes?.cards?.classes || [],
        },
      };
      this.formDefinition = JSON.parse(
        removeArrayPlaceholder(JSON.stringify(result))
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.behaviour_slider_item {
  height: 100%;
}
::v-deep .v-slide-group__content {
  justify-content: center;
}
.behavior_current_item {
  ::v-deep {
    margin-top: -10px;
    .behaviour_atman_component {
      box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
        0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12) !important;
    }
  }
}
</style>
