import { clone, getFilterString, combineConditions } from "@/util";
import { omit, isPlainObject } from "lodash";
import { fieldsWithSearch } from "@/components/fields";

const debug = require("debug")("atman.components.list.util"); // eslint-disable-line

const isAlphabeticSearch = (value) => {
  if (!Array.isArray(value)) {
    return false;
  }

  return value.every((valueString) => {
    return valueString.match(/^([0-9]|[a-z]){1}$/);
  });
};

const defaultSearchFn = (options = {}) => {
  const methodDebug = debug.extend("defaultSearchFn"); //eslint-disable-line
  const { key, searchData, resultObj } = options || {};
  const searchKeyValue = searchData[key] || "";

  if (!searchKeyValue) {
    return true;
  }

  const resultKeyString = (resultObj[key] || "").toLowerCase().trim();

  if (typeof searchKeyValue == "string") {
    const searchKeyString = searchKeyValue.toLowerCase().trim();
    const result = resultKeyString.includes(searchKeyString);
    methodDebug(key, searchData, resultObj, result);
    return result;
  }

  if (isAlphabeticSearch(searchKeyValue)) {
    return searchKeyValue.some((value) => {
      const valueString = value.toLowerCase().trim();
      return resultKeyString.startsWith(valueString);
    });
  }

  return true;
};

const searchUtil = (inputSearchData, results = [], fields = []) => {
  const methodDebug = debug.extend("searchUtil"); //eslint-disable-line
  if (!inputSearchData) {
    methodDebug(`search criteria is empty. resetting results`);
    return results;
  }
  if (typeof inputSearchData == "string") {
    methodDebug(`Performing simple search`);
    if (!inputSearchData.trim().length) {
      methodDebug(`search criteria is empty. resetting results`);
      return results;
    }
    let filteredResults = results.filter((resultObj) => {
      return (
        JSON.stringify(Object.values(resultObj))
          .toLowerCase()
          .indexOf(inputSearchData.toLowerCase()) != -1
      );
    });
    return filteredResults;
  }
  if (isPlainObject(inputSearchData)) {
    methodDebug(`Performing advanced search`, inputSearchData);

    const keys = Object.keys(inputSearchData);
    methodDebug(`search object contains criteria: `, keys);

    let filteredResults = results.filter((resultObj) => {
      const result = keys.every((key) => {
        const field = fields.find(({ name }) => name == key);
        const fieldDefinition = fieldsWithSearch.find(
          ({ definition }) => definition.type == field?.type
        );
        let searchFn = fieldDefinition?.definition?.search;
        if (typeof searchFn != "function") {
          searchFn = defaultSearchFn;
        }
        const payload = {
          key,
          searchData: inputSearchData,
          resultObj,
        };
        methodDebug(`payload`, payload);
        return searchFn(payload);
      });
      // methodDebug(`returning from filter method`,  resultObj, result);
      return result;
    });
    methodDebug(`returning from advanced search`, filteredResults);
    return filteredResults;
  }
};

const prepareSearchFields = (columns = [], data) => {
  const methodDebug = debug.extend("prepareSearchFields"); //eslint-disable-line
  const searchFields = fieldsWithSearch.map(
    ({ definition }) => definition.type
  );
  let filteredColumns = columns.filter((col) => {
    return (
      searchFields.includes(col.type) &&
      col?.display?.attributes?.search_disabled !== true &&
      !col.masked
    );
  });
  let virtualFields = [];
  columns.forEach((col) => {
    const isGroupedField = col.type == "object" && col.is_container == true;
    if (isGroupedField) {
      virtualFields = [...virtualFields, ...col.fields];
    }
  });
  let filteredVirtualFields = virtualFields.filter((col) => {
    return (
      searchFields.includes(col.type) &&
      col?.display?.attributes?.search_disabled !== true &&
      !col.masked
    );
  });
  methodDebug(`virtual fields`, filteredVirtualFields);
  methodDebug(`columns eligible for search`, filteredColumns);
  let fields = [...filteredColumns, ...filteredVirtualFields].map((col) => {
    const fieldDefinition = fieldsWithSearch.find(
      ({ definition }) => definition.type == col.type
    );
    const definition = clone(fieldDefinition.definition.searchField(col, data));
    definition.display.attributes["hide-details"] = true;
    return omit(definition, "mandatory", "rules", "conditions");
  });
  return fields;
};
const PERFORM_BULK_OPERATIONS = "bulk_operation";

const cleanUpSearchData = (data) => {
  let searchData = clone(data || {});
  // HACK - Need to fix - BEGIN
  delete searchData["address_object"];
  delete searchData["country"];
  // HACK - Need to fix - END

  Object.keys(searchData).forEach((key) => {
    const value = searchData[key];
    if (!value) {
      delete searchData[key];
    } else if (Array.isArray(value) && !value.length) {
      delete searchData[key];
    }
  });

  const keys = Object.keys(searchData);
  if (!keys.length) {
    debug(`search object is empty. resetting results`);
    searchData = null;
  }
  return searchData;
};

const getClickAction = (fields = []) => {
  let actionFields = fields.filter((field) => field.type == "action");

  let actionsFields =
    (fields.find((field) => field.type == "actions") || {}).value || [];

  let actions = [...actionFields, ...actionsFields].filter(
    (action) => action?.mode != "ignored"
  );

  if (actions.length == 0) {
    debug(`No actions available`);
    return;
  }

  let actionDefinition = actions.find((action) => action.name == "view");

  if (!actionDefinition) {
    // If view is not available, default to the edit action
    actionDefinition = actions.find((action) => action.name == "edit");
  }

  // IF no edit action is found, find the first navigation/dialog action
  if (!actionDefinition) {
    actionDefinition = actions.find((action) => {
      const actionType = action.value?.type;
      return actionType == "navigation" || actionType == "dialog";
    });
  }
  if (!actionDefinition) {
    debug(`No navigation action found. Aborting`);
    return;
  }
  return actionDefinition;
};

export {
  getClickAction,
  searchUtil,
  combineConditions,
  cleanUpSearchData,
  prepareSearchFields,
  getFilterString,
  PERFORM_BULK_OPERATIONS,
  isAlphabeticSearch,
};
