<template>
  <v-col v-if="effectiveFilters.length" :sm="display.width" cols="12">
    <v-list
      :class="filterClasses"
      dense
      aut-list-filter
      expand
      class="filter_list"
    >
      <v-list-group
        v-for="(filter, index) in effectiveFilters"
        :key="index"
        :value="isOpen"
        :aut-filter-group="filter.name"
        class="filter_group"
      >
        <template v-slot:activator>
          <v-list-item-title class="behavior_overflow">
            {{ filter.label }}
          </v-list-item-title>
        </template>
        <template v-if="filter.options && filter.options.value">
          <div
            class="float-right behavior_clickable pr-4 text-caption"
            v-if="isActive(filter.name)"
            aut-clear-filter
            @click="filterList(filter.name)"
          >
            Clear All
          </div>
          <v-list-item
            v-for="(subfilter, i) in filter.options.value"
            :key="i"
            :aut-filter="subfilter.name"
            class="filter-item"
            :class="{ hidden: isHiddenItem(filter, i) }"
            @click="filterList(filter.name, subfilter.id)"
          >
            <v-checkbox
              dense
              :input-value="isActive(filter.name, subfilter.id)"
              :hide-details="true"
            >
              <template v-slot:label>
                <v-list-item-title class="behavior_overflow">
                  {{ subfilter.name }}
                </v-list-item-title>
              </template>
            </v-checkbox>
          </v-list-item>
          <div
            class="d-flex justify-end behavior_clickable pr-4 text-caption"
            v-if="isLongList(filter)"
            @click="toggleDisplay(filter)"
            aut-show-more
          >
            {{
              fullListFilters.includes(filter.name) ? "Show less" : "Show more"
            }}
          </div>
        </template>
      </v-list-group>
    </v-list>
  </v-col>
</template>
<script>
import { forOwn, isEqual } from "lodash";
import { clone } from "@/util";
const debug = require("debug")("atman.components.list.list_helper"); // eslint-disable-line

export default {
  name: "ListFilter",
  props: {
    filters: {
      type: Array,
      default: () => {
        return [];
      },
    },
    display: {
      type: Object,
      default() {
        return {};
      },
    },
    context: {
      type: String,
    },
    criteria: {},
  },
  data() {
    return {
      effectiveFilters: [],
      activeFilter: {},
      fullListFilters: [],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.fetchData();
      },
    },
    activeFilter() {
      debug("change in active filter", this.activeFilter);
      this.$emit("filter", this.activeFilter);
    },
    criteria() {
      const criteria = clone(this.criteria || {});
      if (!isEqual(this.activeFilter, criteria)) {
        this.activeFilter = criteria;
      }
    },
  },
  mounted() {
    if (this.criteria) {
      this.activeFilter = clone(this.criteria || {});
    }
    this.fetchData();
    debug("mounted effectiveFilters", this.effectiveFilters);
  },
  computed: {
    isOpen() {
      if (this.isTabletOrSmaller) {
        return false;
      }
      return this.display.position == "left";
    },
    filterClasses() {
      debug("Filter Classes", this.display?.classes);
      return this.display?.classes || [];
    },
    maxVisibleItem() {
      return this.display?.visible_count || 5;
    },
  },
  methods: {
    filterList(field, value) {
      debug("add filter", { field, value });
      const activeFilter = clone(this.activeFilter);
      const filterValue = activeFilter[field];

      if (!field) {
        this.activeFilter = {};
        return;
      }

      if (!value) {
        delete activeFilter[field];
        this.activeFilter = activeFilter;
        return;
      }

      if (!filterValue?.length) {
        activeFilter[field] = [value];
        this.activeFilter = activeFilter;
        return;
      }

      const optionIndex = filterValue.findIndex((option) => option == value);

      if (optionIndex != -1) {
        filterValue.splice(optionIndex, 1);
      } else {
        filterValue.push(value);
      }

      activeFilter[field] = filterValue;
      forOwn(activeFilter, (value, key) => {
        if (!value) {
          delete activeFilter[key];
        }
        if (Array.isArray(value) && !value.length) {
          delete activeFilter[key];
        }
      });
      this.activeFilter = activeFilter;
    },
    async fetchData() {
      const component = this;
      const effectiveFilters = [];
      const listFilters = clone(component.filters);

      for (const index in component.filters) {
        const filter = listFilters[index];
        const options = filter?.options;
        const optionsValue = await component.$store.dispatch(
          `${component.context}/getSeedData`,
          {
            definition: {
              options,
            },
          }
        );

        filter.options = {
          value: optionsValue.map(({ name, id }) => {
            return { name, id };
          }),
        };

        effectiveFilters.push(filter);
      }

      debug("fetch data", { effectiveFilters });

      this.effectiveFilters = effectiveFilters;
    },
    isActive(field, value) {
      if (!value) {
        return this.activeFilter?.[field]?.length;
      }

      const filterValue = this.activeFilter?.[field] || [];
      return filterValue.includes(value);
    },
    isLongList(filter) {
      return filter?.options?.value?.length > this.maxVisibleItem;
    },
    toggleDisplay(filter) {
      const fullListFilters = this.fullListFilters || [];
      const index = fullListFilters.findIndex(
        (filterName) => filterName == filter.name
      );
      if (index > -1) {
        fullListFilters.splice(index, 1);
      } else {
        fullListFilters.push(filter.name);
      }

      this.fullListFilters = fullListFilters;
    },
    isHiddenItem(filter, index) {
      const result =
        index > this.maxVisibleItem - 1 &&
        !this.fullListFilters.includes(filter?.name);
      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-item {
  min-height: 0px;
  ::v-deep {
    .v-input--checkbox {
      margin: 0px;
      padding: 0px;
    }
  }
}

.filter_list {
  height: 100%;
  .filter_group:last-child {
    position: sticky;
    top: var(--header-height, 0px);
  }
}
</style>
