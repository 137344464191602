<template>
  <v-col
    :cols="effectiveWidth"
    class="behaviour_search_helper px-2 px-md-auto"
    aut-search-helper
    v-if="showSearch"
  >
    <div v-if="isSimpleSearch">
      <v-text-field
        aut-simple-list-search
        v-model="search"
        append-icon="mdi-magnify"
        placeholder="Press enter or click the magnify icon to trigger search"
        label="Search"
        dense
        outlined
        clearable
        single-line
        v-on:keydown.enter.stop.prevent="emitSimpleSearch(search)"
        @click:append="emitSimpleSearch(search)"
        hide-details
        @click:clear="emitSimpleSearch()"
        class="mb-2"
      >
        <template v-slot:prepend v-if="getAdvanceSearchIcon()">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aut-toggle-advanced-search
                v-bind="attrs"
                v-on="on"
                elevation="2"
                icon
                class="mt-n2"
                @click="isSimpleSearch = !isSimpleSearch"
              >
                <v-icon>{{ getAdvanceSearchIcon() }}</v-icon>
              </v-btn>
            </template>
            Perform Advanced Search
          </v-tooltip>
        </template>
      </v-text-field>
      <div v-if="searchHint" class="text-caption">{{ searchHint }}</div>
    </div>
    <div v-else-if="formDefinition">
      <!-- On small screens, the advanced search is taking up too much space
      Reducing the padding to try and make the component more compact -->
      <AtmanComponent
        :key="formKey"
        class="py-2"
        :context="contextObject"
        :definition="formDefinition"
        @update-data="updateSearchObject"
      >
        <v-card-actions class="pa-0 pt-1">
          <v-spacer></v-spacer>
          <v-btn
            text
            aut-advanced-search-btn
            @click.stop="emitAdvancedSearch"
            class="ml-0"
          >
            Search
            <v-icon color="primary">mdi-magnify</v-icon>
          </v-btn>
          <v-btn
            text
            aut-advanced-search-reset-btn
            @click.stop="resetAdvancedSearch"
            class="ml-0"
          >
            Reset
            <v-icon color="secondary">mdi-reload</v-icon>
          </v-btn>
          <v-btn
            text
            aut-advanced-search-cancel-btn
            @click.stop="removeAdvancedSearch"
            class="ml-0"
          >
            Cancel
            <v-icon color="secondary">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </AtmanComponent>
    </div>
  </v-col>
</template>
<script>
import searchMixin from "@/components/pageContent/List/searchMixin";
import { prepareSearchFields, cleanUpSearchData } from "./util.js";
const debug = require("debug")("atman.components.list.search_helper"); // eslint-disable-line

export default {
  name: "SearchHelper",
  mixins: [searchMixin],
  components: {
    AtmanComponent: () => import("@/components/pageContent/AtmanComponent"),
  },
  watch: {
    isSimpleSearch() {
      if (!this.isSimpleSearch && typeof this.criteria == "string") {
        this.emitSimpleSearch();
      }
      this.emitFilter();
      this.createFormDefinition();
    },
    criteria() {
      this.initializeSearch();
    },
  },
  props: {
    definition: {
      type: Object,
    },
    context: {
      type: String,
    },
    width: {
      type: String,
    },
    criteria: {},
  },
  computed: {
    searchHint() {
      const component = this;
      let result = component.definition?.definition?.search?.hint;
      return result;
    },
    effectiveWidth() {
      if (this.isTabletOrSmaller) {
        return `12`;
      }
      if (this.width) {
        return this.width;
      }
      return `12`;
    },
  },
  data() {
    return {
      isSimpleSearch: true,
      formDefinition: null,
      search: "",
      filter: null,
      contextObject: this.$store.state[this.context].context,
      actionAction: [
        {
          id: "remove_search",
          label: "Clear Search",
          icon: "mdi-cancel",
          event: "remove_search",
        },
      ],
      formKey: 1,
      searchedObject: null,
    };
  },
  mounted() {
    this.emitFilter();
    this.initializeSearch();
    this.createFormDefinition();
  },
  methods: {
    updateSearchObject(inputs) {
      debug(`updateSearchObject`, inputs);
      this.$set(this, "searchedObject", inputs);
    },
    createFormDefinition() {
      const component = this;
      if (component.isSimpleSearch) {
        component.formDefinition = null;
        return;
      }
      const fields = prepareSearchFields(
        component.definition?.definition?.fields || [],
        component.filter
      );

      debug(`fields`, fields);
      component.formDefinition = {
        title: "Advanced Search",
        id: "advanced_search",
        type: "form",
        definition: {
          fields,
        },
        display: {
          attributes: {
            flat: true,
          },
          classes: ["px-4", "pb-4", "transparent"],
        },
      };
    },
    emitSimpleSearch(search = "") {
      let output = search.trim();
      debug(`Triggering [simple] search`, output);
      this.$emit("search", output);
    },
    removeAdvancedSearch() {
      this.resetAdvancedSearch();
      this.isSimpleSearch = true;
      this.emitSimpleSearch();
    },
    resetAdvancedSearch() {
      this.filter = null;
      this.searchedObject = {};
      this.createFormDefinition();
      this.formKey++;
    },
    emitAdvancedSearch() {
      const data = cleanUpSearchData(this.searchedObject);
      debug(`Triggering [advanced] search`, data);
      this.$emit("search", data);
    },
    getAdvanceSearchIcon() {
      const fields = prepareSearchFields(
        this.definition?.definition?.fields || []
      );
      const isAdvanceSearchable = fields.length > 0;
      const result = isAdvanceSearchable ? "mdi-filter-menu" : "";
      debug(`getAdvanceSearchIcon: [${result}]`);
      return result;
    },
    emitFilter() {
      this.$emit("filter", !this.isSimpleSearch);
    },
    initializeSearch() {
      if (this.criteria) {
        if (typeof this.criteria == "string") {
          this.search = this.criteria;
        } else if (Object.keys(this.criteria).length) {
          try {
            this.filter = this.criteria;
            this.isSimpleSearch = false;
          } catch (e) {
            console.error(`malformed filter`);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.behaviour_search_helper {
  ::v-deep {
    .behaviour_component_title {
      padding: 0px !important;
    }
    .behavior_content_title {
      margin-bottom: 0px !important;
    }
    .behavior_form_fields {
      padding: 0px 0px !important;
      margin: 0px;
    }
    .behavior_form_field {
      padding: 0px 2px 0px 2px !important;
      .behaviour_field {
        padding: 0px !important;
      }
    }
  }
}
</style>
