import ProfileLayout from "./ProfileLayout";
import SubscriptionPlanLayout from "./SubscriptionPlanLayout";
import ContactCardLayout from "./ContactCardLayout.vue";
import ProductCardLayout from "./ProductCardLayout.vue";

export default {
  profile: ProfileLayout,
  subscription_plan: SubscriptionPlanLayout,
  contact_card: ContactCardLayout,
  product_card: ProductCardLayout,
};
