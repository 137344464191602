<template>
  <draggable
    handle=".dragger"
    v-model="list"
    :class="classes"
    @end="$emit('update', list)"
  >
    <slot></slot>
  </draggable>
</template>
<script>
import { clone } from "@/util";
import draggable from "vuedraggable";
const debug = require("debug")("atman.components.form_fields");
debug(`form_fields`);
export default {
  name: "FormFields",
  components: {
    draggable,
  },
  data() {
    return {
      list: clone(this.fields),
    };
  },
  props: {
    fields: {
      type: Array,
    },
    classes: {
      type: String,
      default: () => "",
    },
  },
  methods: {},
};
</script>
