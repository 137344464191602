<template>
  <v-col cols="12">
    <div v-if="!loaderToggle && isPaginated && paginatorToggle">
      <div class="pa-4">
        <div v-if="endNumber" class="float-right d-flex pb-2 text-h6 mx-auto">
          Results : {{ startNumber + 1 }} - {{ endNumber }}
        </div>
        <div v-if="!endNumber" class="float-right d-flex pb-2 text-h6 mx-auto">
          Results : 0 - 0
        </div>
        <v-pagination
          aut-page-navigator
          v-model="page"
          :length="numberOfPages"
          circle
          v-on="$listeners"
        ></v-pagination>
      </div>
    </div>
  </v-col>
</template>
<script>
const debug = require('debug')('atman.components.list.list_paginator')  // eslint-disable-line
export default {
  name: "ListPaginator",
  props: {
    pagination: {
      type: Object,
    },
    loaderToggle: {
      type: Boolean,
    },
    paginatorToggle: {
      type: Boolean,
    },
    value: {
      type: Number,
    },
    pagination_start: {
      type: Number,
    },
    stop_pagination: {
      type: Boolean,
    },
    totalRecords: {
      type: Number,
    },
  },
  data() {
    return {
      page: this.value || 1,
    };
  },
  computed: {
    endNumber() {
      let result = this.startNumber + this.totalRecords;

      if (this.startNumber >= result) {
        return false;
      }

      return result;
    },
    numberOfPages() {
      let result = this.value + 1;
      if (this.stop_pagination) {
        result = this.value;
      }
      debug(
        `stop_pagination: [${this.stop_pagination}], value: [${this.value}], numberOfPages: [${result}]`
      );

      return result;
    },

    startNumber() {
      let result = this.pagination_start;

      return result;
    },
    Item_per_page() {
      return this.pagination.count;
    },
    isInfiniteScroll() {
      return this.pagination.type == "infinite_scroll";
    },
    isPaginated() {
      return this.pagination.type == "paginated";
    },
  },
  methods: {
    loadNextPage() {
      debug(`loadNextPage`, this.page, this.value);
    },
    loadPrevPage() {
      debug(`loadPrevPage`, this.page, this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .v-pagination__item,
  .v-pagination__more {
    pointer-events: none;
  }
}
</style>
