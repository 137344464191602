<template>
  <v-dialog
    v-model="displayDialog"
    width="800"
    @keydown.esc.stop.prevent="$emit('close')"
    @click:outside="$emit('close')"
  >
    <v-card aut-bulk-operation-dialog>
      <DialogTitle @close="$emit('close')" title="Bulk Operations Dialog" />
      <v-card-text>
        <div>
          <span>This change will affect </span>
          <span aut-bulk-operation-records>{{ list.length }}</span>
          <span> record(s)</span>
        </div>
        <v-autocomplete
          aut-bulk-operations-field-name
          v-model="fieldToUpdate"
          :items="fields"
          item-text="label"
          item-value="id"
          label="Choose the field to update"
          :multiple="false"
          @change="updateDefinition"
        >
        </v-autocomplete>
        <Field
          aut-bulk-operations-field-value
          v-if="fieldToUpdate && fieldDefinition"
          :key="fieldDefinition.name"
          :path="fieldDefinition.name"
          :definition="fieldDefinition"
          :context="fieldContext"
          @update="updateValue"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')" aut-action-bulk-operation-cancel>
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          aut-action-bulk-operation-update
          @click="performBulkOperation"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { defaultsDeep } from "lodash";
import { store, STORE_CONSTS } from "@/components/fields/store";
const debug = require("debug")("atman.components.bulk_operation_dialog");
debug(`bulk_operation_dialog`);
export default {
  name: "BulkOperationsDialog",
  mixins: [dialogMixin],
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  props: {
    definition: {
      type: Object,
    },
    list: {
      type: Array,
    },
    context: {
      type: String,
    },
  },
  data() {
    const fields = this.definition.definition.fields
      .filter((field) => !!field.bulk_operation)
      .map((col) => {
        return {
          id: col.name,
          label: col.label,
        };
      });

    return {
      displayDialog: true,
      fields: fields,
      fieldToUpdate: null,
      fieldDefinition: null,
      newValue: null,
    };
  },
  mounted() {
    const component = this;
    const storeKey = (component.storeKey = `bulk_operations}`);
    component._createStore(storeKey, store);
    component.fieldContext = storeKey;
    component.updateStore();
  },
  methods: {
    updateDefinition() {
      if (!this.fieldToUpdate) {
        return;
      }
      const column = this.definition.definition.fields.find((col) => {
        return col.name == this.fieldToUpdate;
      });
      const fieldDefinition = defaultsDeep(
        {
          label: "New Value",
          mode: "input",
        },
        column
      );
      debug(`fieldDefinition`, fieldDefinition);
      this.fieldDefinition = fieldDefinition;
    },
    updateStore() {
      const component = this;
      component.$store.commit(
        `${component.fieldContext}/${STORE_CONSTS.DEFINITION}`,
        component.fieldDefinition
      );
    },
    updateValue(value) {
      this.newValue = value;
    },
    async performBulkOperation() {
      const component = this;
      const data = {
        action: "UPDATE",
        value: {
          [component.fieldToUpdate]: component.newValue,
        },
      };
      debug(`in performBulkOperation`, data, component.definition.apis.data);

      await component.$store.dispatch(`${component.context}/triggerAction`, {
        actionDefinition: {
          bulk_operation: true,
          value: {
            type: "post",
            url: component.definition.apis.data.url,
            params: data,
            success: {
              message: "Bulk Updates were successfull",
            },
          },
        },
      });
      component.$emit("close");
    },
  },
};
</script>
