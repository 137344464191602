<template>
  <v-list-item @click.stop="navigateToLink">
    <v-list-item-avatar
      v-if="itemAvatar"
      aut-list-avatar
      v-bind="avatarAttributes"
    >
      <v-img
        v-if="avatarSrc"
        aut-avatar-image
        :src="avatarSrc"
        :lazy-src="no_image"
      ></v-img>
      <span
        v-else-if="charValue"
        class="white--text text-h5"
        aut-list-item-char
      >
        {{ charValue }}
      </span>
    </v-list-item-avatar>
    <v-list-item-icon v-else-if="itemIcon">
      <v-icon v-bind="iconAttributes">
        {{ getValue(itemIcon, item) }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-container
        v-if="fieldContext"
        class="behavior-list-item justify-start d-flex"
      >
        <v-row class="py-0 px-1" align="center" aut-list-item>
          <v-col v-for="(field, i) in listFields" :key="i" class="pa-0 ma-0">
            <Field
              :definition="field"
              :path="field.name"
              :context="fieldContext"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
import {
  replacePlaceholders,
  clone,
  uniqueID,
  hasAVariable,
  fetchRandomValue,
} from "@/util";
import { STORE_CONSTS } from "@/constants";
import { mapState } from "vuex";
import { getFileSrc } from "@/util.js";

const debug = require("debug")("list_item"); // eslint-disable-line

const replaceVariableIdentifiers = (input) => {
  return input
    .replace(/_row->/g, "") //{_row->id} becomes {id}
    .replace(/_data->\[i\]\./g, "") //{_data->[i].id} becomes {id}
    .replace(/_row\./g, "") //{_row.id} becomes {id}
    .replace(/_data\.\[i\]\./g, "") //{_data.[i].id} becomes {id}
    .replace(/\[i\]\./g, ""); //{[i].id} becomes {id}
};

export default {
  name: "ListItem",
  components: {
    Field: () => import("@/components/fields/Field"),
  },
  props: {
    context: {
      type: String,
    },
    item: {
      type: Object,
    },
    fields: {
      type: Array,
    },
    attributes: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let listFields = clone(this.fields || []).map((field) => {
      delete field.label;
      return field;
    });

    listFields = JSON.stringify(listFields);
    listFields = JSON.parse(replaceVariableIdentifiers(listFields));
    return {
      isActive: false,
      fieldContext: "",
      listFields,
      avatarPlaceholder: "",
    };
  },
  computed: {
    ...mapState(["no_image"]),
    itemAvatar() {
      return this.attributes?.avatar?.value || "";
    },
    avatarAttributes() {
      const attributes = clone(this.attributes?.avatar || {});
      delete attributes.value;
      return attributes;
    },
    avatarSrc() {
      let src = this.getImageSrc(this.getValue(this.itemAvatar, this.item));

      if (hasAVariable(src)) {
        src = "";
      }

      const ifMissing = this.attributes?.avatar?.if_missing;
      if (!src && ifMissing == "placeholder") {
        return this.avatarPlaceholder || "/no_image.jpg";
      }

      return src;
    },
    charValue() {
      const charField = this.avatarAttributes?.character || "";
      if (!charField) {
        return "";
      }

      const value = this.getValue(charField, this.item) || "";
      return value[0].toUpperCase();
    },
    iconAttributes() {
      const attributes = clone(this.attributes?.icon || {});
      delete attributes.value;
      return attributes;
    },
    itemIcon() {
      return this.attributes?.icon?.value || "";
    },
  },
  created() {
    this.getValue = replacePlaceholders;
  },
  mounted() {
    debug(`in listItem`, this.listFields, this.item);
    this.createContext();
    this.setPlaceholderValue();
  },
  methods: {
    navigateToLink() {
      const hyperlink = this.$el.querySelector("a");
      if (typeof hyperlink?.click == "function") {
        hyperlink.click();
      }
    },
    getImageSrc(image) {
      if (typeof image == "string") {
        return image;
      } else if (typeof image?._file == "string") {
        return image._file;
      } else {
        return "";
      }
    },
    async createContext() {
      const component = this;
      let { store } = await import("@/components/fields/store");
      const fieldContext = `list_item_${uniqueID()}`;
      component._createStore(fieldContext, store);
      const context = component.$store.state[this.context].context;
      component.$store.commit(
        `${fieldContext}/${STORE_CONSTS.CONTEXT}`,
        context
      );
      component.$store.commit(
        `${fieldContext}/${STORE_CONSTS.DATA}`,
        clone(component.item)
      );
      component.fieldContext = fieldContext;
    },
    async setPlaceholderValue() {
      const placeholder = this.attributes?.avatar?.placeholder || {};

      if (typeof placeholder == "string") {
        this.avatarPlaceholder = placeholder;
        return;
      }

      const result = await fetchRandomValue({
        store: this.$store,
        context: this.context,
        url: placeholder?.url,
        field: placeholder?.field,
        customFunction: getFileSrc,
      });

      this.avatarPlaceholder = result;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .behavior-text-field {
    margin-top: 10px;
  }
  .v-list-item__icon {
    &:first-child {
      margin-right: 8px !important;
    }
  }
  .v-avatar.v-list-item__avatar {
    &:first-child {
      margin-right: 8px !important;
    }
  }
  /*.behavior-list-item:hover {
    border-radius: 4px;
    background-color: var(--v-secondary-lighten3);
  }*/
}
</style>
