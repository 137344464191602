<template>
  <v-card
    flat
    class="pa-0 ma-0 subscription_plan"
    :class="planTitleID"
    min-height="350px"
  >
    <div class="planTitle">
      <p class="pa-4 ma-0 text-h6 text-center" aut-plan-title>
        {{ planTitle }}
      </p>
    </div>
    <div class="pa-4 text-center planAmount" v-if="planAmount">
      <p class="text-h4 ma-0 text-center" aut-plan-amount>
        {{ planAmount | amount }}
      </p>
      <div>
        <span aut-plan-duration>{{ planDuration }}</span> |
        <span aut-plan-users>{{ planUsers }}</span>
      </div>
    </div>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>
<script>
import { toLower } from "lodash";
const debug = require("debug")("atman.layouts.subscription_layout"); // eslint-disable-line
export default {
  name: "SubscriptionPlanLayout",
  data() {
    return {
      planTitle: "",
      planAmount: null,
      planUsers: null,
      planDuration: null,
    };
  },
  props: {
    layout: {
      type: Object,
      default: () => {
        return {};
      },
    },
    context: {
      type: String,
    },
  },
  watch: {
    layout() {
      this.determineEffectiveLayout();
    },
  },
  computed: {
    planTitleID() {
      return `plan_${toLower(this.planTitle)}`;
    },
    amountValue() {
      const result = this.planAmount?.unit_price?.value?.amount;
      debug(`amountValue`, this.planAmount, result);
      return result;
    },
  },
  mounted() {
    this.determineEffectiveLayout();
  },
  methods: {
    async getValue(field) {
      const component = this;
      const value = await component.$store.dispatch(
        `${component.context}/getFieldValue`,
        { path: field }
      );
      return value;
    },

    async determineEffectiveLayout() {
      this.planTitle = await this.getValue("name");
      this.planAmount = await this.getValue("cost");
      const planUsers = await this.getValue("no_of_users");
      this.planUsers = `${planUsers} user${planUsers > 1 ? "s" : ""}`;
      const planDuration = await this.getValue("duration");
      if (!planDuration) {
        this.planDuration = "Forever";
      } else {
        this.planDuration = `${planDuration.value} months`;
      }
      debug(`planTitle`, this.planTitle, `planAmount`, this.planAmount);
    },
  },
};
</script>
<style lang="scss" scoped>
.subscription_plan {
  color: white;
  /*.planTitle {
    border-bottom: 1px solid var(--v-secondary-lighten4);
  }
  .planAmount {
    border-bottom: 1px solid var(--v-secondary-lighten4);
  }*/
  &.plan_free {
    .planTitle {
      background-color: #8e9dcc;
    }
    .planAmount {
      background-color: #4b4e6d;
    }
  }
  &.plan_silver {
    .planTitle {
      background-color: #a3b18a;
    }
    .planAmount {
      background-color: #353535;
    }
  }
  &.plan_gold {
    .planTitle {
      background-color: #ffa43b;
    }
    .planAmount {
      background-color: #cf5c36;
    }
  }
  &.plan_platinum {
    .planTitle {
      background-color: #6d466b;
    }
    .planAmount {
      background-color: #412234;
    }
  }
  &.plan_corporate {
    .planTitle {
      background-color: #6eb257;
    }
    .planAmount {
      background-color: #26532b;
    }
  }
}
</style>
