import client from "./client";
import server from "./server";
import { clone } from "@/util";

const debug = require("debug")("atman.components.list.mixin"); // eslint-disable-line

const resetPaginatedResults = (component) => {
  let mode = component.paginationMode || "client";
  switch (mode) {
    case "client": {
      client.resetPaginatedResults(component);
      break;
    }
    case "server": {
      server.resetPaginatedResults(component);
      break;
    }
  }
};

const loadNextPage = (component) => {
  let mode = component.paginationMode || "client";
  switch (mode) {
    case "client": {
      client.loadNextPage(component);
      break;
    }
    case "server": {
      server.loadNextPage(component);
      break;
    }
  }
};

const loadPrevPage = (component) => {
  if (component.index == 0) {
    debug(`Ignoring prev pagination trigger`);
    return;
  }
  let mode = component.paginationMode || "client";
  switch (mode) {
    case "client": {
      client.loadPrevPage(component);
      break;
    }
    case "server": {
      server.loadPrevPage(component);
      break;
    }
  }
};

const handleSearch = async (component) => {
  let mode = component.pagination?.mode || "client";
  switch (mode) {
    case "client": {
      return client.handleSearch(component);
    }
    case "server": {
      return server.handleSearch(component);
    }
  }
};

const getRuntimeQueryParams = (component) => {
  let mode = component.pagination?.mode || "client";
  switch (mode) {
    case "client": {
      return client.getRuntimeQueryParams(component);
    }
    case "server": {
      return server.getRuntimeQueryParams(component);
    }
  }
};

const getEffectiveResults = (component, fetchedData) => {
  let mode = component.pagination?.mode || "client";
  switch (mode) {
    case "client": {
      return client.getEffectiveResults(component, fetchedData);
    }
    case "server": {
      return server.getEffectiveResults(component, fetchedData);
    }
  }
};

const setPaginationFlags = (component, fetchedData) => {
  let mode = component.pagination?.mode || "client";
  switch (mode) {
    case "server": {
      return server.setPaginationFlags(component, fetchedData);
    }
  }
};

const reloadEffectiveResults = (component) => {
  let mode = component.pagination?.mode || "client";
  let result = component.results;
  debug(
    `in reloadEffectiveResults: results`,
    component.results,
    component.pagination?.mode
  );
  switch (mode) {
    case "server": {
      component.$set(component, "filteredResults", clone([...(result || [])]));
      component.$set(component, "initialised", true);
      break;
    }
    case "client": {
      client.handleSearch(component);
      break;
    }
  }
  debug(`in reloadEffectiveResults`, component.filteredResults);
};

export default {
  reloadEffectiveResults,
  resetPaginatedResults,
  loadNextPage,
  loadPrevPage,
  handleSearch,
  getRuntimeQueryParams,
  getEffectiveResults,
  setPaginationFlags,
};
