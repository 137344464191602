<template>
  <v-card
    class="behavior_list_card pa-2 elevation-1"
    :class="form_definition.display.classes"
    v-bind="form_definition.display.attributes"
    v-if="is_static"
  >
    <Field
      :key="index"
      :definition="augmentedFieldDefinition"
      :context="context"
      :path="path"
      :index="index"
    />
  </v-card>
  <AtmanComponent
    v-else
    class="behavior_list_card"
    :context="contextObject"
    :definition="form_definition"
    :data="item"
  />
</template>
<script>
import { clone } from "@/util";
const debug = require("debug")("atman.components.list.list_card"); // eslint-disable-line
export default {
  name: "ListCard",
  components: {
    AtmanComponent: () => import("@/components/pageContent/AtmanComponent"),
    Field: () => import("@/components/fields/Field"),
  },
  props: {
    is_static: {},
    index: {},
    field_definition: {},
    form_definition: {},
    context: {},
    item: {},
  },
  computed: {
    contextObject() {
      return this?.$store?.state[this?.context]?.context || {};
    },
    path() {
      return `${[this.index]}`;
    },
    augmentedFieldDefinition() {
      const definition = clone(this.field_definition);
      definition.fields.forEach((field) => {
        const value = this.item[field.name];
        if (value) {
          field.value = value;
        }
      });
      debug(`definition`, definition, `item`, this.item);
      return definition;
    },
  },
};
</script>
